import React, { useState } from 'react';
import Block from '../block';
import Slider from 'react-slick';
import { RichText } from '../richTextOptions';
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import { ratingReview } from '../utilities'

const DefaultSectionTestimonialSlider = (section) => {
     const settings = {
          dots: false,
          arrows: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2.999999,
          centerMode: true,
          centerPadding: 0,
          // responsive: [
          //      {
          //           breakpoint: 921,
          //           settings: {
          //                slidesToShow: 1,
          //                arrows: false,
          //                dots: true,
          //           }
          //      },
          // ],
     };
     return (
          <section className={`slider`}>
               <div className="testimonial-desktop testimonial-slider">
                    <Slider {...settings}>
                         {section.testimonials && (
                              section.testimonials.map((block, i) => {
                                   return (<Block block={block} key={i}></Block>)
                              })
                         )}
                    </Slider>
               </div>
               <div className="testimonial-mobile testimonial-slider">
                    <Slider infinite={true} speed={500} centerMode={true} centerPadding={0} dots={true} slidesToShow={1} arrows={false}>
                         {section.testimonials && (
                              section.testimonials.map((block, i) => {
                                   return (<Block block={block} key={i}></Block>)
                              })
                         )}
                    </Slider>
               </div>
          </section>
     )
}

const SectionTestimonialSliderV2 = (section) => {
     const { testimonials } = section;
     const testimonialsLength = testimonials?.length || 0;
     const [activeIndex, setActiveIndex] = useState(0);

     const desktopSettings = {
          dots: false,
          arrows: testimonialsLength > 4 ? true : false,
          infinite: true,
          speed: 500,
          slidesToShow: 4,
          centerPadding: 0,
          draggable: testimonialsLength > 4 ? true : false,
     };
     const mobileSettings = {
          dots: true,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          variableWidth: true
     };
     return (
          <section className={`testimonial-slider-v2 ${section?.sectionClass}`}>
               <div className="testimonial-desktop">
                    <Slider {...desktopSettings}>
                         {
                              Array.isArray(testimonials) && (
                                   testimonials.map((block, i) => {
                                        return (
                                             <div key={i} className={activeIndex === i ? 'item-active' : ''} onClick={() => setActiveIndex(i)}>
                                                  <Block block={block} ></Block>
                                             </div>
                                        )
                                   })
                              )
                         }
                    </Slider>
               </div>
               <div className="testimonial-mobile">
                    <Slider {...mobileSettings}>
                         {
                              Array.isArray(testimonials) && (
                                   testimonials.map((block, i) => {
                                        return (<Block block={block} key={i}></Block>)
                                   })
                              )
                         }
                    </Slider>
               </div>
          </section>
     )
}
const SectionTestimonialPetInsurance2Slider = (section) => {
     const { testimonials } = section;
     const testimonialsLength = testimonials?.length || 0;
     const [activeIndex, setActiveIndex] = useState(0);

     const desktopSettings = {
          dots: false,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          centerPadding: 0,
     };
     const mobileSettings = {
          dots: true,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          variableWidth: true
     }; 
     return (
          <section className={`testimonial-slider-v2 ${section?.sectionClass}`}>
               <div className="testimonial-desktop">
                    <Slider {...desktopSettings}>
                         {
                              Array.isArray(testimonials) && (
                                   testimonials.map((block, i) => {
                                        return (
                                             <div key={i} className={activeIndex === i ? 'item-active' : ''} onClick={() => setActiveIndex(i)}>
                                                  <Block block={block} ></Block>
                                             </div>
                                        )
                                   })
                              )
                         }
                    </Slider>
               </div>
               <div className="testimonial-mobile">
                    <Slider {...mobileSettings}>
                         {
                              Array.isArray(testimonials) && (
                                   testimonials.map((block, i) => {
                                        return (<Block block={block} key={i}></Block>)
                                   })
                              )
                         }
                    </Slider>
               </div>
          </section>
     )
}
const SectionTestimonialPetInsurance3Slider = (section) => {
     const { testimonials } = section;
     const testimonialsLength = testimonials?.length || 0;
     const [activeIndex, setActiveIndex] = useState(0);

     // const desktopSettings = {
     //      dots: false,
     //      arrows: false,
     //      infinite: true,
     //      speed: 500,
     //      slidesToShow: 3,
     //      centerPadding: 0,
     // };
     const mobileSettings = {
          dots: true,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          variableWidth: true
     };
     const settings = {
          dots: false,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          centerPadding: 0,
          responsive: [
               {
                    breakpoint: 1199,
                    settings: {
                         dots: false,
                         arrows: false,
                         infinite: true,
                         speed: 500,
                         slidesToShow: 2,
                         centerPadding: 0,
                    }
               },
               // {
               //                breakpoint: 921,
               //                settings: {
               //                     slidesToShow: 1,
               //                     arrows: false,
               //                     dots: true,
               //                }
               //           },
          ],
     };
     return (
          <section className={`testimonial-slider-v2 ${section?.sectionClass}`}>
               <div className="testimonial-desktop">
                    <Slider {...settings}>
                         {
                              Array.isArray(testimonials) && (
                                   testimonials.map((block, i) => {
                                        return (
                                             <div key={i} className={activeIndex === i ? 'item-active' : ''} onClick={() => setActiveIndex(i)}>
                                                  <Block block={block} ></Block>
                                             </div>
                                        )
                                   })
                              )
                         }
                    </Slider>
               </div>
               <div className="testimonial-mobile">
                    <Slider {...mobileSettings}>
                         {
                             Array.isArray(testimonials) && (
                              testimonials.map((block, i) => {
                                   return (
                                        <div key={i} className={activeIndex === i ? 'item-active' : ''} onClick={() => setActiveIndex(i)}>
                                             <Block block={block} ></Block>
                                        </div>
                                   )
                              })
                         )
                         }
                    </Slider>
               </div>
          </section>
     )
}

const SectionTestimonialLandingPageSlider = (section) => {
     const { headline, rateNumber, rateText, testimonials } = section
     const rateScore = ratingReview(rateNumber)

     const settings = {
          loop: true,
          // autoplay: true,
          // autoplaySpeed: 5000,
          speed: 600,
          dots: true,
          arrows: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplayHoverPause: true,
          responsive: [
               {
                    breakpoint: 1191,
                    settings: {
                         slidesToShow: 2,
                    }
               },
               {
                    breakpoint: 768,
                    settings: {
                         slidesToShow: 1,
                    }
               },
          ],
          beforeChange: (current, next) => {
               $('.customer-reviews-slider .slick-slide a').attr('tabindex', -1)
          },
          afterChange: (current, next) => {
               $('.customer-reviews-slider .slick-slide.slick-current a').attr('tabindex', 0)
          }
     };

     return (
          <section className="testimonial-landing">
               <div className="container">
                    <div className="headline">
                         {headline && <RichText data={headline}></RichText>}
                    </div>
                    <div className="testimonial-rate">
                         {rateText && (
                              <div className="text-rate"><p>{rateText}</p></div>
                         )}
                         {
                              rateScore !== 0 && (
                                   <>
                                        <div className="star-rate">
                                             <div className="rating" style={{ transform: `translateX(-${rateScore}%)` }}>
                                                  <img loading="lazy" style={{ transform: `translateX(${rateScore}%)` }} src="/images/icons/icon-star-rating.png" alt="star ratting" />
                                             </div>
                                             <div className="not-rating">
                                                  <img loading="lazy" src="/images/icons/icon-star-not-rating.png" alt="star not ratting" />
                                             </div>
                                        </div>
                                        <div className="score-rate">
                                             <p>{`${rateNumber}/5`}</p>
                                        </div>
                                   </>
                              )
                         }
                    </div>
                    <div className="landing-testimonial-slider">
                         <div>
                              <Slider {...settings}>
                                   {
                                        Array.isArray(testimonials) && (
                                             testimonials.map((block, i) => {
                                                  return (
                                                       <div key={i} onClick={() => setActiveIndex(i)}>
                                                            <Block block={block} key={i}></Block>
                                                       </div>
                                                  )
                                             })
                                        )
                                   }
                              </Slider>
                         </div>
                    </div>
               </div>
          </section>
     )
}

const SectionTestimonialHomeStatic = (section) => {
     const { testimonials } = section
     //const rateScore = ratingReview(rateNumber)
     
     const settings = {
          loop: true,
          // autoplay: true,
          // autoplaySpeed: 5000,
          speed: 600,
          dots: true,
          arrows: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplayHoverPause: true,
          responsive: [
               //{
                    //breakpoint: 1191,
                    //settings: {
                         //slidesToShow: 2,
                    //}
              // },
               {
                    breakpoint: 991,
                    settings: {
                         slidesToShow: 1,
                    }
               },
          ],
          beforeChange: (current, next) => {
               $('.customer-reviews-slider .slick-slide a').attr('tabindex', -1)
          },
          afterChange: (current, next) => {
               $('.customer-reviews-slider .slick-slide.slick-current a').attr('tabindex', 0)
          }
     };

     return (
          <section className="testimonial-home-static">
               <div className="landing-testimonial-slider">
                         <div>
                              <Slider {...settings}>
                                   {
                                        Array.isArray(testimonials) && (
                                             testimonials.map((block, i) => {
                                                  return (
                                                       <div key={i} onClick={() => setActiveIndex(i)}>
                                                            <Block block={block} key={i}></Block>
                                                       </div>
                                                  )
                                             })
                                        )
                                   }
                              </Slider>
                         </div>
                    </div>
          </section>
     )
}

const SectionTestimonialHomeStaticGroup = (section) => {
     const { testimonials } = section
     //const rateScore = ratingReview(rateNumber)

     const settings = {
          loop: true,
          // autoplay: true,
          // autoplaySpeed: 5000,
          speed: 600,
          dots: true,
          arrows: true,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplayHoverPause: true,
          responsive: [
               //{
                    //breakpoint: 1191,
                    //settings: {
                         //slidesToShow: 2,
                    //}
              // },
               {
                    breakpoint: 991,
                    settings: {
                         slidesToShow: 1,
                    }
               },
          ],
          beforeChange: (current, next) => {
               $('.customer-reviews-slider .slick-slide a').attr('tabindex', -1)
          },
          afterChange: (current, next) => {
               $('.customer-reviews-slider .slick-slide.slick-current a').attr('tabindex', 0)
          }
     };

     return (
          <section className="testimonial-home-static-group">
               <div className="testimonial-slider-group">
                         <div>
                              <Slider {...settings}>
                                   {
                                        Array.isArray(testimonials) && (
                                             testimonials.map((block, i) => {
                                                  return (
                                                       <div key={i} onClick={() => setActiveIndex(i)}>
                                                            <Block block={block} key={i}></Block>
                                                       </div>
                                                  )
                                             })
                                        )
                                   }
                              </Slider>
                         </div>
                    </div>
          </section>
     )
}


const SectionTestimonialLandingPageFullWidth = (section) => {
     const { headline, rateNumber, rateText, testimonials, quoteLeft } = section
     const rateScore = ratingReview(rateNumber)

     return (
          <section className={`testimonial-landing full-width ${quoteLeft ? 'quote-left' : ''}`}>
               <div className="container">
                    <div className="headline">
                         {headline && <RichText data={headline}></RichText>}
                    </div>
                    <div className="testimonial-rate">
                         {rateText && (
                              <div className="text-rate"><p>{rateText}</p></div>
                         )}
                         {
                              rateScore !== 0 && (
                                   <>
                                        <div className="star-rate">
                                             <div className="rating" style={{ transform: `translateX(-${rateScore}%)` }}>
                                                  <img loading="lazy" style={{ transform: `translateX(${rateScore}%)` }} src="/images/icons/icon-star-rating.png" alt="star ratting" />
                                             </div>
                                             <div className="not-rating">
                                                  <img loading="lazy" src="/images/icons/icon-star-not-rating.png" alt="star not ratting" />
                                             </div>
                                        </div>
                                        <div className="score-rate">
                                             <p>{`${rateNumber}/5`}</p>
                                        </div>
                                   </>
                              )
                         }
                    </div>
               </div>
               <Block block={testimonials[0]} ></Block>
          </section>
     )
}

const SectionTestimonialNoSlider = (section) => {
     const { headline, rateNumber, rateText } = section
     const rateScore = ratingReview(rateNumber)

     return (
          <section className={`testimonial-landing no-slider`}>
               <div className="container">
                    <div className="headline">
                         {headline && <RichText data={headline}></RichText>}
                    </div>
                    <div className="testimonial-rate">
                         {rateText && (
                              <div className="text-rate"><p>{rateText}</p></div>
                         )}
                         {
                              rateScore !== 0 && (
                                   <>
                                        <div className="star-rate">
                                             <div className="rating" style={{ transform: `translateX(-${rateScore}%)` }}>
                                                  <img loading="lazy" style={{ transform: `translateX(${rateScore}%)` }} src="/images/icons/icon-star-rating.png" alt="star ratting" />
                                             </div>
                                             <div className="not-rating">
                                                  <img loading="lazy" src="/images/icons/icon-star-not-rating.png" alt="star not ratting" />
                                             </div>
                                        </div>
                                        <div className="score-rate">
                                             <p>{`${rateNumber}/5`}</p>
                                        </div>
                                   </>
                              )
                         }
                    </div>
               </div>
          </section>
     )
}

const SectionVetSliderLogo = (section) => {
     const { testimonials } = section;
     const [activeIndex, setActiveIndex] = useState(0);

     const desktopSettings = {
          autoplay: true,
          dots: false,
          infinite: true,
          speed: 200,
          slidesToShow: 6,
     };
     const mobileSettings = {
          // autoplay: true,
          // dots: true,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          centerMode: true,
          // variableWidth: true
          responsive: [
               {
                    breakpoint: 991,
                    settings: {
                         dots: false,
                         arrows: false,
                         infinite: true,
                         speed: 500,
                         slidesToShow: 4.3,
                         centerPadding: '20px',
                    }
               },
               {
                    breakpoint: 600,
                    settings: {
                         speed: 500,
                         slidesToShow: 3.5,
                         centerPadding: '20px',
                    }
               },
               {
                    breakpoint: 480,
                    settings: {
                         speed: 500,
                         slidesToShow: 2.7,
                         centerPadding: '20px',
                    }
               },
               
          ],
     };
     return (
          <section className={`testimonial-slider-v2 ${section?.sectionClass}`}>
               <div className="container">
                    <div className="testimonial-desktop">
                         <Slider {...desktopSettings}>
                              {
                                   Array.isArray(testimonials) && (
                                        testimonials.map((block, i) => {
                                             return (
                                                  <div key={i} className={activeIndex === i ? 'item-active' : ''} onClick={() => setActiveIndex(i)}>
                                                       <Block block={block} ></Block>
                                                  </div>
                                             )
                                        })
                                   )
                              }
                         </Slider>
                    </div>
               </div>
               <div className="testimonial-mobile">
                    <Slider {...mobileSettings}>
                         {
                              Array.isArray(testimonials) && (
                                   testimonials.map((block, i) => {
                                        return (<Block block={block} key={i}></Block>)
                                   })
                              )
                         }
                    </Slider>
               </div>
          </section>
     )
}

const ContentfulSectionTestimonialSlider = (section) => {
     const getComponent = () => {
          switch (section.layoutTemplate) {
               case 'Version 2':
                    return <SectionTestimonialSliderV2 {...section} />;
               case 'Static Full Width':
                    return <SectionTestimonialHomeStatic {...section} />;
               case 'Static Full Width Group':
                         return <SectionTestimonialHomeStaticGroup {...section} />;
               case 'Pet Insurance 3 Slider Show':
                    return <SectionTestimonialPetInsurance3Slider {...section} />;
               case 'Pet Insurance Slider':
                    return <SectionTestimonialPetInsurance2Slider {...section} />;
               case 'Landing Page':
                    return <SectionTestimonialLandingPageSlider {...section} />;
               case 'No Slider Full Width':
                    return <SectionTestimonialLandingPageFullWidth {...section} quoteLeft={false} />
               case 'No Slider Full Width Quote Left':
                    return <SectionTestimonialLandingPageFullWidth {...section} quoteLeft={true} />
               case 'No Slider':
                    return <SectionTestimonialNoSlider {...section} />
               case 'Vet Slider Logo':
                    return <SectionVetSliderLogo {...section} />
               default:
                    return <DefaultSectionTestimonialSlider {...section} />;
          }
     }

     return (
          <>
               {getComponent()}
          </>
     )
}

export default ContentfulSectionTestimonialSlider;